const carouselsScripts = [];

function createCarousel(_carouselElement) {
  const carouselElement = _carouselElement;
  const prevBtn = carouselElement.querySelector(".prev-arrow");
  const nextBtn = carouselElement.querySelector(".next-arrow");
  const sectionContainer = carouselElement.querySelector(".carousel-sections");
  const centered = carouselElement.getAttribute("data-centered");
  let initPosition = carouselElement.getAttribute("data-init-position");

  let currentIndex = 0;
  let slides = [];
  let dots = [];
  let connectedCarousels = [];
  let sync = true;
  

  function render() {
    if(sync)
      syncedCarouselsGoTo();
    
    if(slides.length > 0) {
      const fullSlidesWidth = slides.length * slides[0].offsetWidth
      if(carouselElement.offsetWidth > fullSlidesWidth) {
        carouselElement.style.width = fullSlidesWidth + "px";
      }
    }

    let offset = 0;

    if(centered && slides.length > 0) {
      const contentWidth = carouselElement.offsetWidth;
      offset += window.innerWidth < contentWidth ?  window.innerWidth /2 : contentWidth / 2;
      offset -= slides[0].offsetWidth /2;
    }

    slides.forEach((slide, index) => {
      if(index == currentIndex)
          slide.classList.add("active")
      else
          slide.classList.remove("active")


      if (index < currentIndex) {
          offset -= slide.offsetWidth;
      }
    });

    

    sectionContainer.style.transform = `translateX(${offset}px)`;
    dots.forEach((dot, index) => {
      index === currentIndex
        ? dot.classList.add("active")
        : dot.classList.remove("active");
    });
    sync = true;
  }

  function prev() {
    if (currentIndex < 1) return;
    currentIndex -= 1;
    render();
  }

  function next() {
    if (currentIndex === slides.length - 1) return;
    currentIndex += 1;
    render();
  }

  function goto(newIndex) {
    if (newIndex < 0 || newIndex > slides.length - 1) return;
    currentIndex = newIndex;
    render();
  }

  function snyc(newIndex) {
      sync = false;
      currentIndex = newIndex;
      render();
      console.log('snyc to' + newIndex);
  }

  function init() {
    const newSlides = carouselElement.querySelectorAll(".carousel-sections > div");
    slides = newSlides;

    const newDots = carouselElement.querySelectorAll(".carousel-dots > div");
    newDots.forEach((dot, index) => {
      dot.onclick = () => goto(index);
    });
    dots = newDots;

    if(centered && initPosition == null ) {
      initPosition = Math.floor(slides.length / 2);
      console.log(initPosition);
    }


    if(initPosition) {
      currentIndex = parseInt(initPosition);
    }


  

    render();
  }

  function collectConnectedCarousels() {
      let attributeString = carouselElement.getAttribute("data-synced-carousels");
      if(attributeString == null) return;
      connectedCarousels = attributeString.split(",");
      console.log(connectedCarousels)
  }

  function syncedCarouselsGoTo() {
      console.log('syncedCarouselsGoTo synced:' + connectedCarousels.length);
      for(let i = 0; i < connectedCarousels.length;i++) {
          console.log(connectedCarousels[i]);
          Carousel(connectedCarousels[i]).snyc(currentIndex);
      }
  }

  prevBtn.onclick = prev;
  nextBtn.onclick = next;

  init();
  collectConnectedCarousels();

  const carousel = {
      carouselElement,
      prevBtn,
      nextBtn,
      sectionContainer,
      currentIndex,
      slides,
      dots,
      render,
      prev,
      next,
      goto,
      snyc,
      init
    };
  carouselsScripts.push(carousel);
}
  
// Initialize each carousel
const carousels = document.querySelectorAll(".carousel");
carousels.forEach(createCarousel);

// Function to get a carousel by classname.
// for example you can call next as: Carousel('main').next();
function Carousel(className) {
    for (let i = 0; i < carouselsScripts.length; i++) {
      if (carouselsScripts[i].carouselElement.className.indexOf(className) != -1) {
        return carouselsScripts[i];
      }
    }
    return null; // Return null if no matching carousel is found
}

// render (and reposition) all carousels
function ReRenderAllCarousel() {
  for(let i = 0; i < carouselsScripts.length; i++) {
    carouselsScripts[i].render();
  }
}